import React, { useState } from "react"
import { Box, Text, Flex } from "@theme-ui/components"
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  MapConsumer,
} from "react-leaflet"
import L from "leaflet"
import "leaflet/dist/leaflet.css"
import MarkerClusterGroup from "react-leaflet-markercluster"
import "react-leaflet-markercluster/dist/styles.min.css"

const customStyle = {}

const zoomInLevel = 14

function LocationMarker({ city, setCity, currentCity }) {
  const icon = new L.Icon({
    iconUrl: require("../../images/map-pin.svg").default,
    iconRetinaUrl: require("../../images/map-pin.svg").default,
    iconAnchor: [18, 50],
    popupAnchor: [-3, -76],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(36, 50),
  })

  const activeIcon = new L.Icon({
    iconUrl: require("../../images/map-pin-active.svg").default,
    iconRetinaUrl: require("../../images/map-pin-active.svg").default,
    iconAnchor: [25, 70],
    popupAnchor: [-3, -76],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(50, 70),
  })
  // console.log(city)
  return (
    <Marker
      position={[city.coordinates.latitude, city.coordinates.longitude]}
      icon={currentCity.originalId === city.originalId ? activeIcon : icon}
      key={city.originalId}
      eventHandlers={{
        click: e => {
          setCity(city)
          e.target["_map"].flyTo(e.latlng, e.target["_map"].getZoom(), {})
        },
      }}
    >
      <Popup>
        <p className="title">{city.name}</p>
        <div dangerouslySetInnerHTML={{ __html: city.body }}></div>
      </Popup>
    </Marker>
  )
}

const BareLocationsMap = ({ locations }) => {
  const [currentCity, setCity] = useState(locations[0])
  const [map, setMap] = useState()
  const locationBounds = locations.map(city => [
    city.coordinates.latitude,
    city.coordinates.longitude,
  ])
  const bounds = locationBounds

  return (
    <Box
      sx={{
        width: ["100%"],
        height: ["500px", "500px", "100%"],
        ".leaflet-container": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      {typeof window !== "undefined" && (
        <MapContainer scrollWheelZoom={true} bounds={bounds} tap={false}>
          <TileLayer url="https://api.mapbox.com/styles/v1/multiconsult/clypfqqj5006t01pihn6ha24e/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibXVsdGljb25zdWx0IiwiYSI6ImNseXBmZWtkOTB0YXQya3F5M3c1cnhrOTQifQ.HHSRcmfAQaP472CEhniP7Q" />
          <MarkerClusterGroup showCoverageOnHover={false}>
            {locations.map(city => (
              <LocationMarker
                map={map}
                city={city}
                key={city.originalId}
                setCity={setCity}
                currentCity={currentCity}
              />
            ))}
          </MarkerClusterGroup>
          <MapConsumer>
            {map => {
              setMap(map)
              return null
            }}
          </MapConsumer>
        </MapContainer>
      )}
    </Box>
  )
}

export default BareLocationsMap
